import ApiEasycatService from '@/api/new/services/api.easycat.service'
import ApiService from '@/api/new/services/api.service'
import i18n from '@/i18n'

const state = {
  showBetaEditor: true,
  showEditorPreview: true,
  currentAiSegment: null,
  currentAiSegmentId: null,
  aiSegmentHistory: [],
  filterInput: null,
  uploadedFile: null,
  segmentStatistics: null,
  failedSegments: null,
  segmentTagMismatch: false,
  repetition: {},
  linkedSegment: null,
  fileErrorCodes: {},
  aiTemplates: [],
  aiMatched: {
    segments: [],
    found: null
  },
  searchPages: {
    current: 1,
    total: 0,
    totalSegments: 0,
    pageSize: 50
  },
  failedPages: {
    current: 1,
    total: 0,
    totalSegments: 0,
    pageSize: 10
  }
}

const mutations = {
  setCurrentAiSegment(state, segment) {
    state.currentAiSegment = segment || null
    state.currentAiSegmentId = segment.id || null
  },
  setAiSegmentHistory(state, segment) {
    state.aiSegmentHistory = segment
  },
  setFilterInput(state, filter) {
    state.filterInput = filter
  },
  setShowBetaEditor(state, show) {
    state.showBetaEditor = show
  },
  setShowEditorPreview(state, show) {
    state.showEditorPreview = show
  },
  setUploadedFile(state, file) {
    state.uploadedFile = file
    state.segmentStatistics = file.attributes.segment_statistics
    state.fileErrorCodes = file.attributes.error_codes
  },
  setFailedSegments(state, segments) {
    state.failedSegments = segments
  },
  setAiMatchedSegments(state, matched) {
    state.aiMatched.segments = matched.segments
    state.aiMatched.found = matched.found
  },
  setSearchPages(state, pages) {
    state.searchPages.current = pages.current_page
    state.searchPages.total = pages.last_page
    state.searchPages.totalSegments = pages.total
    state.searchPages.pageSize = pages.per_page
  },
  setFailedPages(state, pages) {
    state.failedPages.current = pages.current_page
    state.failedPages.total = pages.last_page
    state.failedPages.totalSegments = pages.total
    state.failedPages.pageSize = pages.per_page
  },
  setSegmentTagMismatch(state, isMismatch) {
    state.segmentTagMismatch = isMismatch
  },
  setRepetition(state, repetition) {
    state.repetition = repetition
  },
  setCurrentLinkedSegment(state, segment) {
    state.linkedSegment = segment
  },
  setAiTemplates(state, templates) {
    state.aiTemplates = templates
  }
}

const actions = {
  async getAiFile(
    { state, commit, getters, dispatch, rootGetters },
    { target, currentPage, perPage, failedPage }
  ) {
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/files/${getters.fileId}/file-segments?target_language=${target}`,
      { params: { page: currentPage, perPage: perPage === 0 ? 1 : perPage } }
    )
      .then(async (res) => {
        const file = res.data.data
        const pages = res.data.meta
        commit('task/setRevisionFile', file, { root: true })
        commit('task/setFilePages', pages, { root: true })
        await commit('setAiSegmentHistory', {})
        await dispatch('getUploadedFile')
        if (state.showBetaEditor)
          await dispatch('getFailedSegments', {
            target,
            currentPage: failedPage || state.failedPages.current,
            perPage: 10
          })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getFailedSegments(
    { commit, getters, rootGetters },
    { target, currentPage, perPage }
  ) {
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/files/${getters.fileId}/file-segments/failed?target_language=${target}`,
      { params: { page: currentPage, perPage } }
    )
      .then(async (res) => {
        const segments = res.data.data
        const pages = res.data.meta
        commit('setFailedSegments', segments)
        commit('setFailedPages', pages)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getUploadedFile({ commit, getters, rootGetters }) {
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/files/${getters.fileId}`
    )
      .then(async (res) => {
        const file = res.data.data
        commit('setUploadedFile', file)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateAllSegmentsStatus(
    { dispatch, state, getters, rootGetters, rootState },
    payload
  ) {
    const perPage = rootState['task'].filePages.pageSize
    const data = {
      data: {
        type: 'file_segments',
        attributes: {
          is_confirmed: payload.status,
          target_language: payload.target
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/files/${getters.fileId}/file-segments/bulk-confirm`,
      data
    )
      .then(async () => {
        dispatch(
          'toast/success',
          {
            message: payload.status
              ? i18n.t('customer.toast.success.confirm_segments')
              : i18n.t('shared.toast.success.uncofirm_segments')
          },
          { root: true }
        )
        await dispatch('getAiFile', {
          target: payload.target,
          currentPage: rootState['task'].filePages.current,
          perPage
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateSegment(
    { state, dispatch, commit, getters, rootGetters, rootState },
    payload
  ) {
    const target = rootState['task'].currentTask.attributes.target_language
    const currentPage = rootState['task'].filePages.current
    const perPage = rootState['task'].filePages.pageSize
    const data = {
      data: {
        type: 'file_segments',
        attributes: {
          ...payload.attributes
        }
      }
    }
    await ApiEasycatService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/files/${getters.fileId}/file-segments/${payload.id}`,
      data
    )
      .then(async () => {
        if (state.filterInput) {
          await dispatch('filterAiFileSegments', {
            ...state.filterInput,
            currentPage
          })
        } else {
          await dispatch('getAiFile', {
            target,
            currentPage,
            perPage,
            failedPage: payload.nextPage
          })
        }
        await dispatch('getFileSegmentHistory', payload.id)
        await commit('task/setIsReviewSaved', true, { root: true })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateRepetition(
    { dispatch, getters, rootGetters, rootState },
    payload
  ) {
    const target = rootState['task'].currentTask.attributes.target_language
    const data = {
      data: {
        type: 'file_segments',
        attributes: {
          ...payload.attributes
        }
      }
    }
    await ApiEasycatService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/files/${getters.fileId}/file-segments/${payload.id}`,
      data
    )
      .then(async () => {
        if (payload.getFile)
          await dispatch('getAiFile', {
            target: target,
            currentPage: rootState['task'].filePages.current,
            perPage: rootState['task'].filePages.pageSize
          })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateRepetitions(
    { dispatch, getters, rootGetters, rootState },
    payload
  ) {
    const target = rootState['task'].currentTask.attributes.target_language
    const data = {
      data: {
        type: 'file_segments',
        attributes: {
          ...payload.attributes
        }
      }
    }
    await ApiEasycatService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/files/${getters.fileId}/file-segments/repetitions-propagate`,
      data
    )
      .then(async () => {
        await dispatch('getAiFile', {
          target: target,
          currentPage: rootState['task'].filePages.current,
          perPage: rootState['task'].filePages.pageSize
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async filterAiFileSegments(
    { dispatch, commit, getters, rootState, rootGetters },
    payload
  ) {
    const perPage = rootState['task'].filePages.pageSize
    const { all, currentPage, ...attributes } = payload
    const data = {
      data: {
        type: 'files',
        attributes: {
          ...attributes
        }
      }
    }
    if (!payload.value && payload.all) {
      await commit('setFilterInput', null)
      return await dispatch('getAiFile', {
        target: payload.target_language,
        currentPage: rootState['task'].filePages.current,
        perPage
      })
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/files/${getters.fileId}/search?perPage=${perPage}&page=${payload.currentPage || 1}`,
      data
    )
      .then(async (res) => {
        const file = res.data.data
        const pages = res.data.meta
        commit('task/setRevisionFile', file, { root: true })
        commit('task/setFilePages', pages, { root: true })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async searchAndReplace(
    { dispatch, getters, rootState, rootGetters },
    payload
  ) {
    const target = rootState['task'].currentTask.attributes.target_language
    const source = rootState['task'].currentTask.attributes.source_language
    const data = {
      data: {
        type: 'files',
        attributes: {
          target_language: target,
          search_value: payload.search,
          replace_value: payload.replace,
          segment_ids: payload.segmentIds
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/files/${getters.fileId}/replace-all`,
      data
    )
      .then(async () => {
        await dispatch('filterAiFileSegments', {
          source_language: source,
          target_language: target,
          value: payload.search,
          type: 'target'
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getFileSegmentHistory({ commit, getters, rootGetters }, id) {
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/files/${getters.fileId}/file-segments/${id}/history`
    )
      .then(async (res) => {
        const history = res.data.data
        await commit('setAiSegmentHistory', history)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getFuzzySegments({ commit, getters, rootGetters }, id) {
    await ApiEasycatService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/files/${getters.fileId}/file-segments/${id}/fuzzy`
    )
      .then(async (res) => {
        const segments = res.data.data
        const matchedFound = segments.length > 0 ? true : false
        const obj = {
          found: matchedFound,
          segments
        }
        commit('setAiMatchedSegments', obj)
        commit('setSearchPages', {
          current: 1,
          total: 0,
          pageSize: 50
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async searchAiTm({ commit, rootGetters }, payload) {
    const data = {
      data: {
        type: 'translation_memories',
        attributes: {
          source_language: payload.source,
          target_language: payload.target,
          value: payload.value,
          type: payload.type
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/translation-memories/search?page=${payload.currentPage}`,
      data
    )
      .then(async (res) => {
        const segments = res.data.data
        const pages = res.data.meta
        const matchedFound = segments.length > 0 ? true : false
        const obj = {
          found: matchedFound,
          segments
        }
        commit('setAiMatchedSegments', obj)
        commit('setSearchPages', pages)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async searchAiTb({ commit, rootGetters }, payload) {
    const data = {
      data: {
        type: 'term_banks',
        attributes: {
          source_language: payload.source,
          target_language: payload.target,
          value: payload.value,
          type: payload.type
        }
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/term-banks/search?page=${payload.currentPage}`,
      data
    )
      .then(async (res) => {
        const segments = res.data.data
        const pages = res.data.meta
        const matchedFound = segments.length > 0 ? true : false
        const obj = {
          found: matchedFound,
          segments
        }
        commit('setAiMatchedSegments', obj)
        commit('setSearchPages', pages)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async finishTranslation({ rootState, rootGetters }) {
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/projects/${rootState['project'].currentProject.id}/tasks/${rootState['task'].currentTask.id}/complete-translation`
    ).catch((err) => {
      throw new Error(JSON.stringify(err))
    })
  },
  async filterAiTemplates({ commit, rootGetters }, payload) {
    const data = {
      data: {
        type: 'templates',
        attributes: payload
      }
    }
    await ApiEasycatService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/templates/filter`,
      data
    )
      .then(async (res) => {
        const templates = res.data.data
        commit('setAiTemplates', templates)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}
const getters = {
  fileId: (state, getters, rootState, rootGetters) => {
    return rootState['project'].currentProject.attributes.cattool_id
  },
  isAiCompleted: (state, getters, rootState, rootGetters) => {
    return (
      rootState['task'].currentTask.attributes.status === 'COMPLETED' ||
      rootState['task'].currentTask.attributes.type === 'internal_review'
    )
  },
  currentPageFailedSegments: (state, getters, rootState, rootGetters) => {
    const threshold = state.uploadedFile.attributes.pipeline.qa.threshold
    const failedSegments = rootState['task'].file.filter(
      (s) => s.attributes.qa && s.attributes.qa.average <= threshold
    )
    return failedSegments
  },
  getLinkedSegment: () => (s) => {
    let linkedSegments = [...s.attributes.linked_repetitions]
    const segment = {
      order: s.attributes.order,
      repetition: s.attributes.repetition,
      source: s.attributes.source,
      target: s.attributes.target,
      _id: s.id
    }
    linkedSegments.push(segment)
    return [...linkedSegments].sort((a, b) => a.order - b.order)
  }
}

export const aiFile = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
